var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedThought
    ? _c(
        "v-layout",
        {
          staticClass: "thought-edit-card",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("thought-form", {
                attrs: {
                  cancellable: "",
                  thought: _vm.loadedThought,
                  loading: _vm.loading
                },
                on: { "thought-submit": _vm.updateThought }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }