<template>
<v-layout row wrap pt-3 gap-xs-3 class="thought-edit-card" v-if="loadedThought">
  <v-flex xs12>
    <thought-form
      cancellable
      :thought="loadedThought"
      :loading="loading"
      @thought-submit="updateThought">
    </thought-form>
  </v-flex>
</v-layout>
</template>

<script>
import thoughtForm from '@/views/tools/thoughts/components/thought-form.vue';

export default {
  name: 'addThoughtHome',
  components: {
    thoughtForm,
  },
  props: { thought: Promise },
  data() {
    return {
      loading: false,
      loadedThought: null,
    };
  },
  mounted() {
    this.thought.then(res => {
      this.loadedThought = res;
    });
  },
  methods: {
    updateThought(event) {
      this.loading = true;
      this.$store.dispatch('thoughts/editThought', event)
        .then(res => {
          if (res && (!res.status || res.status < 300)) {
            this.$store.commit('setNotification', { text: this.$t('app.tools.thought_balancer.notifications.updated') });
            this.$router.push({ name: 'thoughts-list', query: { tab: '1' } });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
